const IMAGES = {
    img1: {
        height: "100vh",
        position: "center",
        filter: "", 
        text: "Dan & Maíra"
    },
    img2: {
        height: "80vh",
        position: "center",
        filter: "", 
    }   
};

export default IMAGES; 